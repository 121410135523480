<template>
  <page-layout page-name="录入企业信息" :show-home="false" :show-icon="false" style="width: 50%;margin: 0 auto;">
    <div class="main">
      <van-form label-width="120px">
        <van-cell>
          <van-field
            label="企业名称"
            v-model="formData.nsrmc"
            maxlength="35"
            required
            clickable
            placeholder="请输入企业名称"
          ></van-field>
        </van-cell>
        <van-cell>
          <van-field
            label="企业税号"
            v-model="formData.nsrsbh"
            maxlength="20"
            required
            clickable
            placeholder="请输入企业税号"
          ></van-field>
        </van-cell>
      </van-form>
      <div class="apply-btn">
        <van-button
          type="primary"
          block
          size="large"
          :loading="loading"
          loading-text="生成中"
          @click="nextStep"
        >生成授权链接
        </van-button>
      </div>
    </div>
  </page-layout>
</template>

<script>
import {
  Button,
  Notify,
  Dialog,
  Form,
  Cell,
  Field,
  Row,
  Col,
  NavBar
} from "vant";
import ETaxApi from "../api/etax";

export default {
  name: "EtaxAuthStep1",
  components: {
    [Button.name]: Button,
    [Notify.name]: Notify,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    
  },
  props: {
    pCode: String,
    orgId: String,
    staffId: String,
    v: String,
    newOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pName: "",
      formData: {
        nsrmc: "",
        nsrsbh: "",
        fddbrxm: "",
        fddbrsjh: "",
      },
      enterprise: {
        enterpriseName: "",
        creditCode: "",
      },
      taxGrade: "",
      popup: {
        show: false,
        message: "",
      },
      loading: false,
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.nsrmc = newVal.enterpriseName;
        this.formData.nsrsbh = newVal.creditCode;
      },
    },
  },
  mounted() {
  },
  methods: {
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    handleEnterpriseSelectorShow() {
      this.$refs["enterprise-selector"].open();
    },
    handleTaxGradeSelectorShow() {
      this.$refs["tax-grade-selector"].open();
    },
    nextStep() {
      if (!this.formData.nsrmc) {
        // Notify({ type: 'warning', message: '企业名称不能为空' });
        Dialog.alert({ message: "企业名称不能为空", confirmButtonText: "我知道了" });
        return;
      }
      if (!this.formData.nsrsbh) {
        // Notify({ type: 'warning', message: '企业税号不能为空' });
        Dialog.alert({ message: "企业税号不能为空", confirmButtonText: "我知道了" });
        return;
      }
      this.loading = true;
      ETaxApi.generateAuthLink(this.formData.nsrsbh, this.formData.nsrmc)
        .then((res) => {
          window.location.href = res.data
        })
        .finally(() => {
        })
    },
  },
};
</script>

<style scoped>
.main {
  background-color: #ffffff;
  margin: 15px;
  border-radius: 15px;
  padding-bottom: 50px;
  font-size: 24px;
}

.tips {
  margin: 30px 30px 0px 30px;
  padding-top: 10PX;
  line-height: 80px;
}

.apply-btn {
  margin: 50px 12px 50px 12px;
}

.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>